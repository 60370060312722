import { AnalyticsEventArgs, useAnalytics } from '@pff-consumer/analytics'
import { checkoutHooks } from '@pff-consumer/data-provider/lib/checkout/checkout.hooks'
import { useCallback } from 'react'

const { useBreakpoints, useGrootUid } = checkoutHooks

export const useSubscribeAnalyticsEvent = () => {
  const { isDesktop } = useBreakpoints()
  const uid = useGrootUid()
  const { sendAnalyticsEvent } = useAnalytics()
  const skipNextJsAuth = true

  const sendSubscribeAnalyticsEvent = useCallback(
    (...args: AnalyticsEventArgs) => {
      const [eventName, eventProperties = {}] = args
      const device = isDesktop ? 'Desktop' : 'Mobile Web'

      const allEventProperties = { ...eventProperties, device, pffUserId: uid, skipNextJsAuth }

      const analyticsArgs = [eventName, allEventProperties] as AnalyticsEventArgs
      sendAnalyticsEvent(...analyticsArgs)
    },
    [isDesktop, uid, sendAnalyticsEvent, skipNextJsAuth]
  )

  return { sendSubscribeAnalyticsEvent }
}
